.info-bubble {
  background: #fff;
  padding: 1.875rem;
  border-radius: 8px;
  box-shadow: 0 2px 16px rgba(46, 46, 46, 0.15);
  font-size: 1rem;
}
.info-bubble p:last-child {
  margin-bottom: 0;
}
.info-bubble.thank-you {
  text-align: center;
  padding: 3rem;
}
.info-bubble.thank-you hr {
  color: #9bd6da;
  border: 2px solid;
  margin: 15px auto;
  border-radius: 8px;
  width: 128px;
}
.info-bubble.thank-you .thanks-content {
  margin-top: 2rem;
}
.info-bubble.thank-you .thanks-content p {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
}
.info-bubble.thank-you .thanks-content p.bigger {
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 0;
}

.merge-card-top {
  background: #fff;
  padding: 1.875rem 1.875rem 1.875rem 1.875rem;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0 0 16px rgba(46, 46, 46, 0.15);
  font-size: 1rem;
}
.merge-card-bottom {
  background: #fff;
  padding: 1.875rem 1.875rem 1.875rem 1.875rem;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 16px 16px rgba(46, 46, 46, 0.15);
  font-size: 1rem;
  margin-top: -24px;
}

/* image on top, text below card */
.info-card {
  position: relative;
  max-width: 285px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0 2px 16px rgb(46 46 46 / 15%);
  overflow: hidden;
  transition: all 0.2s linear;
}
.info-card > a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.95rem;
  transition: all 0.2s linear;
}
.info-card:hover {
  background: #007cb7;
}
.info-card img {
  width: 100%;
  height: auto;
}
.info-card .title {
  display: block;
  width: 100%;
  padding: 12px 10px;
  text-align: center;
}
.info-card:hover .title {
  color: #fff;
}

/* image on left, text on right card */
.info-bar {
  position: relative;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 2px 16px rgb(46 46 46 / 15%);
  overflow: hidden;
  transition: all 0.2s linear;
}
.info-bar:hover {
  background: #007cb7;
}
.info-bar > a {
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s linear;
}
.info-bar span.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  overflow: hidden;
}
.info-bar span.image img {
  margin: -20px 0 0 0;
  float: right;
}
.info-bar span.title {
  margin-left: 30%;
  padding: 15px 20px;
  display: block;
}
.info-bar:hover span.title {
  color: #fff;
}

/* misc form styles */
.k-form .k-form-field {
  margin: 3rem 0 0 0;
}

#previousbutton,
#nextbutton {
  transition: all linear 0.25s;
  text-decoration: none;
}
#previousbutton:hover {
  background-color: rgb(3, 124, 183);
  color: #fff;
  border-color: rgb(3, 124, 183);
}
#nextbutton:hover {
  background-color: #fff;
  color: rgb(3, 124, 183);
  border-color: rgb(3, 124, 183);
}

.k-stepper .k-step-label .k-step-text {
  max-width: none !important;
  overflow: visible;
}
.k-stepper .k-step-label {
  max-width: none !important;
}
.k-stepper {
  margin-right: 3rem;
}

.k-form #radio-button-group button {
  margin-right: 10px;
}

.k-form .k-form-fieldset > div:first-child > div > .k-form-field {
  margin-top: 0;
}

div#form-weekly-schedule {
  margin-top: 30px;
}

.form-btn {
  display: inline-block;
  background: #fff;
  padding: 10px 25px;
  border-radius: 15px;
  text-decoration: none;
  color: rgb(3, 124, 183);
  border: solid 1px rgb(3, 124, 183);
  font-weight: 600;
  transition: all 0.2s linear;
  margin: 25px 20px 0 0;
  font-size: 1rem;
}
.form-btn:hover {
  background-color: rgb(3, 124, 183);
  color: #fff;
  text-decoration: none;
}
.form-btn.stacked {
  display: block;
}
.form-btn.primary {
  background-color: rgb(3, 124, 183);
  color: #fff;
}
.form-btn.primary:hover {
  background-color: #fff;
  color: rgb(3, 124, 183);
}

#app-portal-container #mobile-stepper {
  padding: 5px 10px;
  margin-bottom: 1rem;
}

/* Confirmation Box Styles */
.confirm-wrapper .confirm-instructions {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.confirm-wrapper h2 {
  font-size: 1.6rem;
  margin-bottom: 1.4rem;
}

.confirm-wrapper .sep {
  color: #cdcdcd;
  border: 1px solid;
  margin: 19px 0px;
}

.confirm-wrapper .question {
  margin: 0;
  font-weight: 600;
}

.intake-employer-table td {
  padding: 3px;
}

.stack-btns {
  display: block;
}

.next-step-box {
  background: #fff;
  padding: 1.875rem;
  border-radius: 8px;
  box-shadow: 0 2px 16px rgba(46, 46, 46, 0.15);
  font-size: 1rem;
  display: flex;
  margin-bottom: 1rem;
  text-decoration: none;
  transition: all 0.2s linear;
}

.next-step-box:hover {
  filter: drop-shadow(0px 6px 6px #00000014);
  text-decoration: none;
}

.next-step-box img {
  margin-right: 2rem;
  width: auto;
  height: 90px;
}

.next-step-box .next-title {
  display: block;
  color: #037cb7;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
}

.next-step-box .next-copy {
  display: block;
  color: #657676;
}

.next-step-box .blue-link {
  color: #037cb7;
  font-weight: 600;
}

@media only screen and (max-width: 500px) {
  .next-step-box img {
    display: none;
  }
}

.divider:after {
  content: ' ';
  display: block;
  border-bottom: 0.5px solid #dddddd;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
