*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

.tw-absolute {
  position: absolute !important
}

.tw-relative {
  position: relative !important
}

.tw-inset-x-0 {
  left: 0px !important;
  right: 0px !important
}

.tw--top-1 {
  top: -0.25rem !important
}

.tw-right-0 {
  right: 0px !important
}

.tw-right-\[5px\] {
  right: 5px !important
}

.tw-top-0 {
  top: 0px !important
}

.tw-top-2 {
  top: 0.5rem !important
}

.tw-top-7 {
  top: 1.75rem !important
}

.tw-m-0 {
  margin: 0px !important
}

.tw-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important
}

.tw-my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important
}

.tw-my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important
}

.-tw-ml-1 {
  margin-left: -0.25rem !important
}

.-tw-ml-6 {
  margin-left: -1.5rem !important
}

.-tw-mr-1 {
  margin-right: -0.25rem !important
}

.tw--ml-8 {
  margin-left: -2rem !important
}

.tw-mb-0 {
  margin-bottom: 0px !important
}

.tw-mb-0\.5 {
  margin-bottom: 0.125rem !important
}

.tw-mb-2 {
  margin-bottom: 0.5rem !important
}

.tw-mb-3 {
  margin-bottom: 0.75rem !important
}

.tw-mb-4 {
  margin-bottom: 1rem !important
}

.tw-mb-5 {
  margin-bottom: 1.25rem !important
}

.tw-mb-6 {
  margin-bottom: 1.5rem !important
}

.tw-ml-2 {
  margin-left: 0.5rem !important
}

.tw-ml-\[-7px\] {
  margin-left: -7px !important
}

.tw-ml-\[10px\] {
  margin-left: 10px !important
}

.tw-ml-\[20px\] {
  margin-left: 20px !important
}

.tw-ml-\[7px\] {
  margin-left: 7px !important
}

.tw-ml-auto {
  margin-left: auto !important
}

.tw-mr-2 {
  margin-right: 0.5rem !important
}

.tw-mr-3 {
  margin-right: 0.75rem !important
}

.tw-mt-1 {
  margin-top: 0.25rem !important
}

.tw-mt-12 {
  margin-top: 3rem !important
}

.tw-mt-2 {
  margin-top: 0.5rem !important
}

.tw-mt-3 {
  margin-top: 0.75rem !important
}

.tw-mt-4 {
  margin-top: 1rem !important
}

.tw-mt-5 {
  margin-top: 1.25rem !important
}

.tw-mt-\[14px\] {
  margin-top: 14px !important
}

.tw-mt-\[2px\] {
  margin-top: 2px !important
}

.tw-mt-\[5px\] {
  margin-top: 5px !important
}

.tw-mt-\[8px\] {
  margin-top: 8px !important
}

.tw-flex {
  display: flex !important
}

.tw-grid {
  display: grid !important
}

.tw-hidden {
  display: none !important
}

.tw-h-0 {
  height: 0px !important
}

.tw-h-0\.5 {
  height: 0.125rem !important
}

.tw-h-12 {
  height: 3rem !important
}

.tw-h-3 {
  height: 0.75rem !important
}

.tw-h-9 {
  height: 2.25rem !important
}

.tw-h-\[140px\] {
  height: 140px !important
}

.tw-h-\[160px\] {
  height: 160px !important
}

.tw-h-\[180px\] {
  height: 180px !important
}

.tw-h-\[26px\] {
  height: 26px !important
}

.tw-h-\[280px\] {
  height: 280px !important
}

.tw-h-\[32px\] {
  height: 32px !important
}

.tw-h-\[70\%\] {
  height: 70% !important
}

.tw-h-auto {
  height: auto !important
}

.tw-h-full {
  height: 100% !important
}

.tw-w-1 {
  width: 0.25rem !important
}

.tw-w-1\/12 {
  width: 8.333333% !important
}

.tw-w-1\/2 {
  width: 50% !important
}

.tw-w-1\/5 {
  width: 20% !important
}

.tw-w-11\/12 {
  width: 91.666667% !important
}

.tw-w-12 {
  width: 3rem !important
}

.tw-w-2\/5 {
  width: 40% !important
}

.tw-w-4\/5 {
  width: 80% !important
}

.tw-w-80 {
  width: 20rem !important
}

.tw-w-\[100\%\] {
  width: 100% !important
}

.tw-w-\[315px\] {
  width: 315px !important
}

.tw-w-\[360px\] {
  width: 360px !important
}

.tw-w-\[74px\] {
  width: 74px !important
}

.tw-w-\[90\%\] {
  width: 90% !important
}

.tw-w-full {
  width: 100% !important
}

.tw-w-screen {
  width: 100vw !important
}

.tw-max-w-full {
  max-width: 100% !important
}

.tw-rotate-180 {
  --tw-rotate: 180deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

.tw-scale-150 {
  --tw-scale-x: 1.5 !important;
  --tw-scale-y: 1.5 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

.tw-cursor-default {
  cursor: default !important
}

.tw-cursor-not-allowed {
  cursor: not-allowed !important
}

.tw-cursor-pointer {
  cursor: pointer !important
}

.tw-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important
}

.tw-flex-row {
  flex-direction: row !important
}

.tw-flex-col {
  flex-direction: column !important
}

.tw-place-items-center {
  place-items: center !important
}

.tw-items-center {
  align-items: center !important
}

.tw-items-stretch {
  align-items: stretch !important
}

.tw-justify-start {
  justify-content: flex-start !important
}

.tw-justify-end {
  justify-content: flex-end !important
}

.tw-justify-center {
  justify-content: center !important
}

.tw-justify-between {
  justify-content: space-between !important
}

.tw-gap-1 {
  gap: 0.25rem !important
}

.tw-gap-3 {
  gap: 0.75rem !important
}

.tw-gap-5 {
  gap: 1.25rem !important
}

.tw-gap-7 {
  gap: 1.75rem !important
}

.tw-gap-y-4 {
  row-gap: 1rem !important
}

.tw-self-start {
  align-self: flex-start !important
}

.tw-justify-self-start {
  justify-self: start !important
}

.tw-text-nowrap {
  text-wrap: nowrap !important
}

.tw-rounded {
  border-radius: 0.25rem !important
}

.tw-rounded-full {
  border-radius: 9999px !important
}

.tw-rounded-lg {
  border-radius: 0.5rem !important
}

.tw-rounded-md {
  border-radius: 0.375rem !important
}

.tw-rounded-b-lg {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important
}

.tw-rounded-l-md {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important
}

.tw-rounded-r-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important
}

.tw-rounded-t-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important
}

.tw-border {
  border-width: 1px !important
}

.tw-border-x-0 {
  border-left-width: 0px !important;
  border-right-width: 0px !important
}

.tw-border-b-0 {
  border-bottom-width: 0px !important
}

.tw-border-t-0 {
  border-top-width: 0px !important
}

.tw-border-solid {
  border-style: solid !important
}

.tw-border-none {
  border-style: none !important
}

.tw-border-transparent {
  border-color: transparent !important
}

.tw-border-unm-adhoc4 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(224 224 224 / var(--tw-border-opacity)) !important
}

.tw-border-unm-interactive01-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(3 124 183 / var(--tw-border-opacity)) !important
}

.tw-border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important
}

.tw-border-t-unm-interactive01-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(3 124 183 / var(--tw-border-opacity)) !important
}

.tw-bg-\[\#99bad4\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(153 186 212 / var(--tw-bg-opacity)) !important
}

.tw-bg-\[\#D9EBF4\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 235 244 / var(--tw-bg-opacity)) !important
}

.tw-bg-\[\#EAEAEA4D\] {
  background-color: #EAEAEA4D !important
}

.tw-bg-\[\#F5F5F566\] {
  background-color: #F5F5F566 !important
}

.tw-bg-\[\#d9ebf4\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 235 244 / var(--tw-bg-opacity)) !important
}

.tw-bg-\[\#fef3ea\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 243 234 / var(--tw-bg-opacity)) !important
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important
}

.tw-bg-transparent {
  background-color: transparent !important
}

.tw-bg-unm-adhoc1 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 235 244 / var(--tw-bg-opacity)) !important
}

.tw-bg-unm-adhoc3 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(238 241 245 / var(--tw-bg-opacity)) !important
}

.tw-bg-unm-adhoc4 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity)) !important
}

.tw-bg-unm-approved {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(4 168 116 / var(--tw-bg-opacity)) !important
}

.tw-bg-unm-interactive01-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(3 124 183 / var(--tw-bg-opacity)) !important
}

.tw-bg-unm-interactive01-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(2 87 128 / var(--tw-bg-opacity)) !important
}

.tw-bg-unm-pending {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(252 215 24 / var(--tw-bg-opacity)) !important
}

.tw-bg-unm-primary02-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(1 82 148 / var(--tw-bg-opacity)) !important
}

.tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}

.tw-fill-current {
  fill: currentColor !important
}

.tw-fill-unm-primaryblue {
  fill: #007CB7 !important
}

.tw-p-0 {
  padding: 0px !important
}

.tw-p-2 {
  padding: 0.5rem !important
}

.tw-p-3 {
  padding: 0.75rem !important
}

.tw-p-4 {
  padding: 1rem !important
}

.tw-p-5 {
  padding: 1.25rem !important
}

.tw-p-6 {
  padding: 1.5rem !important
}

.tw-px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important
}

.tw-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important
}

.tw-px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important
}

.tw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important
}

.tw-px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important
}

.tw-px-\[6px\] {
  padding-left: 6px !important;
  padding-right: 6px !important
}

.tw-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important
}

.tw-py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important
}

.tw-pb-3 {
  padding-bottom: 0.75rem !important
}

.tw-pb-4 {
  padding-bottom: 1rem !important
}

.tw-pl-3 {
  padding-left: 0.75rem !important
}

.tw-pl-\[15px\] {
  padding-left: 15px !important
}

.tw-pl-\[5px\] {
  padding-left: 5px !important
}

.tw-pr-2 {
  padding-right: 0.5rem !important
}

.tw-pr-4 {
  padding-right: 1rem !important
}

.tw-pt-3 {
  padding-top: 0.75rem !important
}

.tw-text-center {
  text-align: center !important
}

.tw-text-end {
  text-align: end !important
}

.tw-align-middle {
  vertical-align: middle !important
}

.tw-align-bottom {
  vertical-align: bottom !important
}

.tw-align-text-bottom {
  vertical-align: text-bottom !important
}

.tw-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important
}

.tw-text-\[10px\] {
  font-size: 10px !important
}

.tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important
}

.tw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important
}

.tw-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important
}

.tw-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important
}

.tw-font-\[600\] {
  font-weight: 600 !important
}

.tw-font-bold {
  font-weight: 700 !important
}

.tw-font-normal {
  font-weight: 400 !important
}

.tw-font-semibold {
  font-weight: 600 !important
}

.tw-italic {
  font-style: italic !important
}

.tw-leading-tight {
  line-height: 1.25 !important
}

.tw-text-\[\#909090\] {
  --tw-text-opacity: 1 !important;
  color: rgb(144 144 144 / var(--tw-text-opacity)) !important
}

.tw-text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important
}

.tw-text-inherit {
  color: inherit !important
}

.tw-text-unm-adhoc2 {
  --tw-text-opacity: 1 !important;
  color: rgb(82 82 82 / var(--tw-text-opacity)) !important
}

.tw-text-unm-interactive01-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(3 124 183 / var(--tw-text-opacity)) !important
}

.tw-text-unm-interactive01-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(1 50 73 / var(--tw-text-opacity)) !important
}

.tw-text-unm-primary02-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(1 57 104 / var(--tw-text-opacity)) !important
}

.tw-text-unm-primary02-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(102 102 102 / var(--tw-text-opacity)) !important
}

.tw-text-unm-primaryblue {
  --tw-text-opacity: 1 !important;
  color: rgb(0 124 183 / var(--tw-text-opacity)) !important
}

.tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.tw-underline {
  text-decoration-line: underline !important
}

.tw-no-underline {
  text-decoration-line: none !important
}

.tw-shadow-\[0_2px_4px_0px_rgba\(2\,84\,119\,0\.3\)\] {
  --tw-shadow: 0 2px 4px 0px rgba(2,84,119,0.3) !important;
  --tw-shadow-colored: 0 2px 4px 0px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-shadow-\[0_96px_64px_32px_rgba\(2\,2\,2\,0\.3\)\] {
  --tw-shadow: 0 96px 64px 32px rgba(2,2,2,0.3) !important;
  --tw-shadow-colored: 0 96px 64px 32px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-shadow-basic {
  --tw-shadow: 0px 2px 8px 0px #0000001F !important;
  --tw-shadow-colored: 0px 2px 8px 0px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.tw-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

@media not all and (min-width: 1024px) {
  .max-lg\:tw-min-w-full {
    min-width: 100% !important
  }

  .max-lg\:tw-justify-center {
    justify-content: center !important
  }
}

@media not all and (min-width: 768px) {
  .max-md\:tw-min-w-full {
    min-width: 100% !important
  }
}

@media (min-width: 640px) {
  .sm\:tw-flex {
    display: flex !important
  }

  .sm\:tw-h-\[72px\] {
    height: 72px !important
  }

  .sm\:tw-w-\[177px\] {
    width: 177px !important
  }

  .sm\:tw-rounded {
    border-radius: 0.25rem !important
  }
}

@media (min-width: 1024px) {
  .lg\:tw-mb-\[2px\] {
    margin-bottom: 2px !important
  }

  .lg\:tw-ml-4 {
    margin-left: 1rem !important
  }

  .lg\:tw-block {
    display: block !important
  }

  .lg\:tw-inline {
    display: inline !important
  }

  .lg\:tw-flex {
    display: flex !important
  }

  .lg\:tw-hidden {
    display: none !important
  }

  .lg\:tw-h-\[72px\] {
    height: 72px !important
  }

  .lg\:tw-w-96 {
    width: 24rem !important
  }

  .lg\:tw-rounded-full {
    border-radius: 9999px !important
  }
}
